import {Box, CreateToastFnReturn} from "@chakra-ui/react";
import {HttpClient} from "../common/HttpClient";
import {SessionManager} from "../session/SessionManager";
import {ChatController} from "../../components/Chat";


export class ToastService {
    private httpClient: HttpClient;
    private session: SessionManager;
    private chatCtl: ChatController;

    constructor(httpClient: HttpClient, session: SessionManager, chatCtl: ChatController) {
        this.httpClient = httpClient;
        this.session = session;
        this.chatCtl = chatCtl;
    }

    private getHeaders() {
        return {
            "Authorization": "Bearer " + this.session.getSessionData().token,
            "AppKey": this.session.getSessionData().app,
            "PluginMode": this.session.getSessionData().isPluginMode.toString(),
            "sessionId": this.session.getSessionData().sessionId,
        };
    }


    async getQuestions(page_text: string): Promise<any> {
        let data = {
            "page_text": page_text,
        };
        let resp = await this.httpClient.post("/predict/questions", data, this.getHeaders())
        return {
            success: true,
            questions: resp.data
        }
    }
}

