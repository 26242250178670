import {Button, Flex, List, ListItem, Text} from "@chakra-ui/react"
import {LogoutIcon} from "../Icons/Icons";
import "./style.css"
import {useChatContext} from "../../hooks/useChatContext";
import {PropsWithChildren, useEffect, useState} from "react";
import {ChatController} from "../Chat";

type MuiChatParams = {
    chatController?: ChatController;
    isHome?: boolean;
};

const homePrompts = [
    "What's a Natural Language Interface?",
    "Teach Me How To Use This Interface",
    "Can This Interface Carry out Tasks?",
    "Can I Ask This Interface Anything?",
    "Can This Interface Integrate With Other Systems?",
    "Can This Interface Be A Conversational Layer To All Web Systems?",
    "Can You Communicate With Any Backend?",
    "How Much Do The Products Cost?",
    "Open Chatbot",
    "Help Me Send An Email",
    "Add Document"
    
    
]

const adminPrompts = [
    "Register a new admin user as follow: alex; 123; alex@gmail.com",
    "Create a new application",
    "List my applications",
    "List docs for the app {{{app_key}}}",
    "Update user session with app {{{app_key}}}",
    "Add new documentation",
    "Create a new address with the following: Camac crescent, Co. Dublin, Dublin, Ireland, D08f9g9",
    "++ Please return the same command but set addressLine2=Inchicore",
    "Help me to book a free class to my son",
    "Help me to book a free class for tomorrow"
    // "create a new private repository name=test repo and allow squash merge",
    // "++ return the same command but this time include all the available fields option",
    // list my repositories
    // ++ sort by created asc
    // ++ display as a chart with the fields: full_name and forks_count

]
export default function Sidebar({chatController, isHome}: PropsWithChildren<MuiChatParams>) {
    const {dispatch} = useChatContext()
    const [prompts, _] = useState(isHome ? homePrompts : adminPrompts);

    //https://docs.github.com/en/rest/repos/repos?apiVersion=2022-11-28

    function setPrompt(prompt: string) {
        if (!chatController) {
            dispatch({type: "SET_PROMPT", payload: prompt})
            return
        }
        chatController.setActionRequest({
            type: 'text',
            always: true,
            defaultValue: prompt
        })
    }

    return (
        <Flex
            direction="column"
            flexGrow={1}
            align="start"
            textAlign="left"
            p="100px 10px"
            height="100vh" // Ensure the parent takes the full height
        >
            <List 
                spacing={1} 
                w="100%" 
                variant="ghost" 
                flex="1" // This allows the List to take up as much space as possible
                overflowY="auto" // Add scrolling in case of overflow
                border="none" 
                borderRadius="10pt" 
                borderColor="blue.900" 
                color="gray.50"
                sx={{
                    '&::-webkit-scrollbar': {
                      width: '16px',
                      borderRadius: '8px',
                      backgroundColor: 'rgba(0, 0, 0, 0.1)', // Slightly darker background for better contrast
                    },
                    '&::-webkit-scrollbar-thumb': {
                      backgroundColor: 'rgba(128, 128, 128, 0.2)',
                      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.5)', // Inner shadow for a more 3D look
                      transition: 'background-color 0.2s ease-in-out', // Smooth transition for hover effect
                      '&:hover': { // Hover effect
                        backgroundColor: 'rgba(128, 128, 128, 0.5)',
                      },
                    },
                  }}
            >
                {prompts.map((prompt, index) => (
                    <ListItem className={"prompt"} textAlign="left" key={index} onClick={() => {
                        setPrompt(prompt)
                    }}>
                        <Text>{prompt}</Text>
                    </ListItem>
                ))}
            </List>
    
            <Button
                leftIcon={<LogoutIcon/>}
                alignSelf="center"
                size="md"
                variant="ghost"
                height={"50px"}
                borderTop={"1px solid gray.500"}
                width={"100%"}
                bottom={0} // Anchor it to the bottom
                color="gray.500"
                _hover={{
                    bg: "gray.600"
                }}
            >
                Logout
            </Button>
        </Flex>
        );

    }
