import {Box, Fade, Text} from '@chakra-ui/react';
import {Message, MessageContent} from './index';

export function MuiMessage({
                               id,
                               message,
                               showDate,
                               showTime,
                           }: {
    id: string;
    message: Message<MessageContent>;
    showDate: boolean;
    showTime: boolean;
}): React.ReactElement {
    if (message.deletedAt) {
        return <div id={id}/>;
    }

    const dispDate = message.updatedAt ? message.updatedAt : message.createdAt;
    const ChatUsername = (
        <Box maxWidth="100%" mx={1}>
 <Text isTruncated textAlign={message.self ? 'right' : 'left'} fontWeight="bold">
                    {message.username}
            </Text>
        </Box>
    );

    const ChatDate = (
        <Box maxWidth="100%" mx={1}>
            <Text
                isTruncated
                textAlign={message.self ? 'right' : 'left'}
                color="gray.500"
                fontSize={5}
            >
                {dispDate?.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                })}
            </Text>
        </Box>
    );

    return (
        <Fade in className={"chat-message-container"}>
            <Box maxWidth="100%" display="flex" flexDirection="column"  p={3}>
                {showDate && (
                <Text textAlign="center" fontWeight="semibold" mb={2}>
                {dispDate?.toLocaleDateString(["en-ie"], {})}
                    </Text>
                )}
                <Box
                    id={id}
                    maxWidth="100%"
                    my={2}
                    display="flex"
                    justifyContent={message.self ? 'flex-end' : 'flex-start'}
                    style={{ overflowWrap: 'break-word' }}
                    >
                    {message.avatar && !message.self}
                    <Box minWidth={0} display="flex" flexDirection="column" className={message.className}>
                        {message.username && ChatUsername}
                        <Box
        className={`msg-item-box ${message.self ? 'self-message' : 'other-message'}`}
    maxWidth="100%"
    width="100%"
    py={1}
    px={2}
    bg={message.self ? 'blue.600' : 'gray.50'}
    color={message.self ? 'white' : 'black'}
    borderRadius="lg"
    style={{ boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)' }}
    fontSize="lg"
>

                            {message.type === 'text' && (
                                <Text style={{whiteSpace: 'pre-wrap'}}>
                                    {message.content}
                                </Text>
                            )}
                            {message.type === 'jsx' && <div>{message.content}</div>}
                        </Box>
                        {showTime && ChatDate}
                    </Box>
                    {message.avatar && message.self}
                </Box>
            </Box>
        </Fade>
    );
}
