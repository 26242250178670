import {Box, Button, Center, ChakraProvider, Flex, Heading, Image, Text, theme} from "@chakra-ui/react"

export const Home = () => {
    return (
        <ChakraProvider theme={theme}>
            <Center h="100vh" px={[4, 6, 8]} flexDirection="column">
                <Box textAlign="center">
                    <Heading as="h1" size="2xl" mb={4} fontSize={['2xl', '3xl', '4rem']} lineHeight="1.2"
                             maxWidth={['100%', '800px']} color="blue.700">
                        Welcome to{' '}
                        <Box as="span" color="teal.500" wordBreak="break-word">
                            ChatUX
                        </Box>
                    </Heading>
                    <Text fontSize="xl" color="gray.500" mb={8} textAlign="center" maxWidth={['100%', '800px']}>
                        ChatUX is a tool to help the integration of natural language capabilities in any web systems,
                        making them more accessible and user-friendly for everyone
                    </Text>
                    <Button colorScheme="blue" size="lg" onClick={() => {
                        window.location.href = '#/signup'
                    }}>
                        Get Started
                    </Button>
                </Box>
            </Center>
            <Center px={[4, 6, 8]} flexDirection="column">
                <Flex justifyContent="center" alignItems="center" py={12} flexWrap={['wrap', 'wrap', 'nowrap']}>
                    <Box width={['100%', '100%', '50%']} px={6} mb={[8, 8, 0]}>
                        <Heading as="h2" size="xl" mb={4} color="blue.700">
                            Declarative approach
                        </Heading>
                        <Text fontSize="xl" color="gray.500" mb={8}>
                            ChatUX offers a chat interface for users to interact with any system using natural language.
                            Our engine understands the user's intent and executes the required
                            commands for the given task.{' '}
                            Users can easily navigate and utilize complex websites/products with multiple pages and
                            functionalities through a chat interface rather than using a point-and-click
                            approach.{' '}
                            This leads to decreased training expenses, enhanced user experience, and improved
                            productivity.
                        </Text>
                    </Box>
                    <Box width={['100%', '100%', '50%']} px={6}>
                        <Image src="img/chat-commander.png" alt="Image" borderRadius="md"/>
                    </Box>
                </Flex>
            </Center>
            <Center px={[4, 6, 8]} flexDirection="column">
                <Flex justifyContent="center" alignItems="center" py={12} flexWrap={['wrap', 'wrap', 'nowrap']}>
                    <Box width={['100%', '100%', '50%']} px={6} mb={[8, 8, 0]}>
                        <Heading
                            as="h2"
                            size="xl"
                            color="blue.700"
                            fontSize={['2xl', '3xl', '4rem']}
                            m={"40px"}
                        >
                            Chat smarter <Text color={"teal.500"}>with chat based command interface</Text>
                        </Heading>
                        <Text fontSize="xl" color="gray.500" mb={8}>
                            ChatUX will shift user interfaces from the imperative point and click to a declarative
                            describe and done approach
                        </Text>
                    </Box>
                    <Box width={['100%', '100%', '50%']} px={6}>
                        <Image src="img/declarative-imperative.png" alt="Image" borderRadius="md"/>
                    </Box>
                </Flex>
            </Center>
            <Center px={[4, 6, 8]} flexDirection="column">
                <Box m={"40px"}>
                    <Text fontSize="xl" color="gray.500" mb={8}>
                        Imagine an era where users not only visit your website but stay, engage, and come back, time and
                        time again.
                        That era is here, and it is enabled by our ChatUX assistant. This is not just an assistant, it's
                        a revolution in user experience.
                        It allows your customers to access all the functionalities of your web app using natural
                        language, as if they were talking to a human.
                        This direct, intuitive, and simple interaction does more than just delight your users - it
                        creates a bond, a commitment.
                        It turns casual visitors into loyal users, significantly increasing customer retention.
                        With ChatUX, you're not just enhancing your website, you're building the future of user
                        engagement.
                    </Text>
                </Box>
            </Center>
            <Center px={[4, 6, 8]} flexDirection="column">
                <Box m={"40px"}>
                    <Heading
                        as="h2"
                        size="xl"
                        color="blue.700"
                        fontSize={['2xl', '3xl', '4rem']}
                        m={"40px"}
                    >
                        Excited yet? <Text color={"teal.500"}>See how it works</Text>
                    </Heading>
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/RATiphgn8HA"
                            title="YouTube video player" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowFullScreen></iframe>
                </Box>
            </Center>
        </ChakraProvider>
    )
}
