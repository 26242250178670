import {CommandRequestHandler} from "./CommandRequestHandler";
import {ActionRequest, ActionResponse, ChatController, defaultMsgObj} from "../../components/Chat";
import {formatText, TaskCommand} from "./Common";
import {CommandError, CommandFinder} from "./CommandFinder";
import {OnCommandTriggeredParams} from "../common/FlowController";
import {CommandExecution} from "./CommandExecution";
import {CommandResponseHandler} from "../commandresp/CommandResponseHandler";
import {SessionManager} from "../session/SessionManager";
import {HttpClient} from "../common/HttpClient";
import {StreamCompletionClient} from "../common/StreamCompletionClient";
import {chatCtl} from "../Factory";
import {AVATAR_IMG} from "../../pages/chatflow/inputs";
import {useCallback} from "react";
import {Box, Button, FormControl, FormLabel, Input, Spinner, VStack} from "@chakra-ui/react";
import * as React from "react";

export class CommandService {
    private commandReq: CommandRequestHandler;
    private commandFinder: CommandFinder;
    private chatCtl: ChatController;
    private commandExec: CommandExecution;
    private commandResp: CommandResponseHandler;

    constructor(
        commandReq: CommandRequestHandler,
        commandFinder: CommandFinder,
        commandExec: CommandExecution,
        cmdRespHandler: CommandResponseHandler,
        chatCtl: ChatController
    ) {
        this.commandReq = commandReq;
        this.commandFinder = commandFinder;
        this.commandExec = commandExec;
        this.commandResp = cmdRespHandler;
        this.chatCtl = chatCtl;
        chatCtl.getFlowController().addOnCommandTriggered(this.handleCommandTriggered())
    }

    private async handleAnswerStream(input: string, docContext: string) {
        let answer = ""

        await this.chatCtl.addMessage({...defaultMsgObj, content: formatText(answer)})

        this.commandFinder.getAnswerStream(input, docContext,
            (data: any, done: boolean) => {
                if (data.choices[0]?.delta?.content == null) {
                    // 74313897 == Sustaine demo
                    if (SessionManager.getInstance().getSessionData().app == "74313897" && localStorage.getItem("sentEmail") !== "true") {
                        showEmailForm(chatCtl)
                    }
                    return
                }
                answer += data.choices[0]?.delta?.content + ""
                const contAnswer = formatText(answer)
                this.chatCtl.updateMessage(this.chatCtl.getMessages().length - 1, {...defaultMsgObj, content: contAnswer})
            })
    }

    async process(res: ActionResponse): Promise<void> {
        let docResp = await this.commandFinder.getDocumentation(res.value);
        if (!docResp.success) {
            await this.chatCtl.addMessage({...defaultMsgObj, content: docResp.textMessage});
            return
        }
        if (!isAction(docResp.textMessage, res.value)) {
            await this.handleAnswerStream(res.value, docResp.textMessage)
            return
        }
        const commandResp = await this.commandFinder.getCommand(res.value, docResp.textMessage);
        console.log("task", commandResp)
        // @ts-ignore
        if (commandResp.hasOwnProperty("errorCode") && commandResp.errorCode != null) {
            return this.handleCommandError(commandResp as CommandError);
        }
        const taskCommand = commandResp as TaskCommand
        await this.commandReq.handleCommand(taskCommand)
        this.chatCtl.getFlowController().addCommand(taskCommand)
    }

    async handleCommand(command: TaskCommand) {
        await this.commandReq.handleCommand(command)
    }

    private handleCommandTriggered() {
        const self = this
        return async (params: OnCommandTriggeredParams) => {
            const resp = await self.commandExec.executeCommand(params.command, params.args)
            await self.commandResp.handle(resp)
        }
    }

    private async handleQuestionResponse(task: string) {
        let answer = formatText(task)
        await this.chatCtl.addMessage({...defaultMsgObj, content: answer});
    }

    private async handleCommandError(task: CommandError) {
        console.log("error task", task)
        if (task.errorCode == 402) {
            await this.chatCtl.addMessage({...defaultMsgObj, content: "You have exceeded the free allowance for this app."});
            return
        }
        await this.chatCtl.addMessage({...defaultMsgObj, content: "Something went wrong. Please try again later."});
    }
}

export function commandServiceFactory(
    chatCtl: ChatController,
    session: SessionManager,
    httpClient: HttpClient,
    httpClientNoBaseUrl: HttpClient,
    sseClient: StreamCompletionClient
): CommandService {
    const cmdReqHandler = new CommandRequestHandler(chatCtl)
    const cmdRespHandler = new CommandResponseHandler(chatCtl)
    const commandFinder = new CommandFinder(httpClient, sseClient, session)
    const commandExec = new CommandExecution(httpClientNoBaseUrl, session)
    return new CommandService(cmdReqHandler, commandFinder, commandExec, cmdRespHandler, chatCtl)
}

function isAction(doc: string, input: string) {
    if (input.endsWith("?")) {
        return false
    }
    return doc && doc.includes("#action");
}


async function showEmailForm(chatCtl: ChatController) {
    await chatCtl.addMessage({
        type: 'text',
        content: `Would you like to be contacted by a member of our team?`,
        self: false,
        avatar: AVATAR_IMG
    });
    const sel = await chatCtl.setActionRequest({
        type: 'select',
        options: [
            {
                value: 'yes',
                text: 'Yes',
            },
            {
                value: 'no',
                text: 'No',
            },
        ],
    });
    if (sel.value === 'Yes') {
        const good = await chatCtl.setActionRequest({
            type: 'custom',
            Component: ContactForm,
        });
        return
    }
    await chatCtl.setActionRequest({
            type: 'text',
            placeholder: 'Please enter your text.',
            always: true,
        },
    );

}

function ContactForm({
                         chatController,
                         actionRequest,
                     }: {
    chatController: ChatController;
    actionRequest: ActionRequest;
}) {
    const chatCtl = chatController;

    const setResponse = useCallback(async () => {
        localStorage.setItem("sentEmail", "true")
        chatCtl.addMessage({
            type: 'text',
            content: `Thank you for your email. We will get back to you soon.`,
            self: false,
            avatar: AVATAR_IMG
        });
        await chatCtl.setActionRequest({
                type: 'text',
                placeholder: 'Please enter your text.',
                always: true,
            },
        );
    }, [actionRequest, chatCtl]);
    const loading = false
    return (
        <Box bg={"gray.50"} p={4} borderRadius={"md"} width={"md"} >
            <VStack spacing={4}>
                <FormControl id="email" isRequired>
                    <FormLabel>Email address</FormLabel>
                    <Input
                        name={"email"}
                        type="email"
                        placeholder="Enter your email"
                        size="md"
                        // onChange={e => setEmail(e.target.value)}
                    />
                </FormControl>
                <FormControl id="name" isRequired>
                    <FormLabel>Name</FormLabel>
                    <Input
                        type="text"
                        placeholder="Enter your name"
                        size="md"
                        // onChange={e => setPassword(e.target.value)}
                    />
                </FormControl>
                {loading && <Spinner/>}
                {!loading && (<Button colorScheme="blue" onClick={setResponse} mt={4}>
                    Send
                </Button>)}
            </VStack>
        </Box>
    );
}