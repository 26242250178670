import {ChatController, MuiChat} from '../../components/Chat';
import {ReactElement, useEffect} from "react";
import {Avatar, Box, Divider, HStack, Spacer, Text} from "@chakra-ui/react";
import "./index.css"
import {AVATAR_IMG} from "../chatflow/inputs";
import {chatCtl, toastService} from "../../domain/Factory";
import {SessionManager} from "../../domain/session/SessionManager";

const avatarImg = "/chat-commander-ui/img/geek-bot.jpeg"
const session = SessionManager.getInstance();


export function FrontChatbot(): ReactElement {
    useEffect(() => {
        start(chatCtl);
        setIframeEventListener();
    }, []);

    function closeChat() {
        let chatbotIframe = window.parent.document.getElementById("chatbot-iframe-container");
        if (chatbotIframe) {
            chatbotIframe.style.display = "none";
        }
    }

    return (
        <>
            <Box className={"chat-root"} height="100%">
                <Box
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    maxWidth="640px"
                    mx="auto"
                >
                    <HStack px={4} bg={session.getSessionData().appColor} color={"white"} className="chatbot-header">
                        <Avatar height={35} width={35} src={avatarImg}/>
                        <Text p={4}>
                            Powered by <a href="https://newaisolutions.com" target="_blank" style={{color: 'blue'}}>newaisolutions.com</a>
                        </Text>
                        <Spacer/>
                        {/*<Button variant="ghost" size="sm" color={"white"} _hover={{bg: "grey"}} onClick={closeChat}>X</Button>*/}
                    </HStack>
                    <Divider/>
                    <Box flex="1 1 0%" minHeight="0">
                        <MuiChat chatController={chatCtl}/>
                    </Box>
                </Box>
            </Box>
        </>
    );
}

async function start(chatCtl: ChatController): Promise<void> {
    await chatCtl.addMessage({
        type: 'text',
        content: `How can I help you today?`,
        self: false,
        avatar: AVATAR_IMG
    });

    await chatCtl.setActionRequest({
            type: 'text',
            placeholder: 'Please enter your text.',
            always: true,
        },
    );
}

function setIframeEventListener<Result, Err>() {
    window.addEventListener('message', async event => {
        if (event.data.hasOwnProperty("question_click")) {
            console.log("question_click", event.data.question_click)
            chatCtl.setActionRequest({
                type: 'text',
                always: true,
                defaultValue: event.data.question_click
            })
        }
        if (event.data.hasOwnProperty("page_content")) {
            console.log("page_content received");
            const response = await toastService.getQuestions(event.data.page_content)
            if (response.success) {
                window.parent.postMessage({questions: response.questions, parent: "parent"}, "*");
                console.log("page_content sent");
            }
        }
    });
}